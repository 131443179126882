<template>
  <select :disabled="!enabled" @change="changeEvent($event.target.value)">
    <option v-for="(item, index) in states" :value="index">{{ item }}</option>
  </select>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';

    const props = defineProps({startingid: Number, states: Array, enabled: Boolean});
    const {startingid, states, enabled} = toRefs(props);
    const emit = defineEmits(['selection']);

    let stateArray = [];
    stateArray = props.states;

    function changeEvent(id) {
        emit('selection',id);
    }
    
    //Initial value on load
    states.value = stateArray;
    startingid.value = props.startingid;
    enabled.value = props.enabled;

    //Watch for changes to props
    watch(props, () => {
        states.value = props.stateArray;
    });
</script>

<style lang="scss" scoped>

</style>