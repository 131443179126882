<template>
  <main class="main_v8">
    <droptest 
      class="locationDropDisp" 
      :startingid="0" 
      :states=locationStates 
      :enabled=locationDropDownEnabled 
      @selection="locationSelected">
    </droptest>
  </main>
</template>

<script setup>
  import { ref } from 'vue';
  import droptest from '../components/dropdowns/dropdowntest.vue';
  let locationStates = ref("");
  locationStates.value = [];
  let locationDropDownEnabled = ref(true);

  const getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
  fetch(getLocationsUrl, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          console.log("DROPDOWN DATA TEST",response);
          locationStates.value = response;
          locationDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("LOCATION FETCH ERROR " + error);
  });
  
</script>

<style lang="scss" scoped>
  .main_v8{
    padding-top: 100px;
  } 
  
</style>

<!-- 
<template>
  <main class="main_v8">
    <span>SPAN tag text example</span><br/>
    <a href="#" @click.prevent>A tag text example</a><br/>
    <p>P tag text example</p>

    <h1>H1 tag text example</h1>
    <h2>H2 tag text example</h2>
    <h3>H3 tag text example</h3>
  </main>
  

</template>

<script>
export default {
  // created() {
  //   document.querySelector('html').style.fontSize = '21px';
  // }
}
</script>

<style lang="scss" scoped>
  .main_v8{
    padding-top: 100px;
  } 
  
</style> -->


